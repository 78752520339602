import React from "react";
import { ButtonProps } from "../../types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import "./styles.scss";

const Button = (props: ButtonProps): JSX.Element => {
  const generateButtonComponent = (props: ButtonProps): JSX.Element => {
    if (props.block) {
      return <div className="text-center">{generateButton(props)}</div>;
    } else {
      return generateButton(props);
    }
  };

  const generateButton = (props: ButtonProps): JSX.Element => (
    <button
      onClick={props.onClick}
      onBlur={props.onBlur}
      disabled={props.disabled || props.loading}
      className={`${
        props.className
          ? `${generateClassName(props)} ${props.className}`
          : generateClassName(props)
      }`}
    >
      {props.loading ? (
        <FontAwesomeIcon icon={faSpinner} className="button-loading" />
      ) : (
        props.children
      )}
    </button>
  );

  const generateClassName = (props: ButtonProps): string => {
    if (props.primary) {
      return "software_banner_btn";
    }
    if (props.secondary) {
      return "btn_secondary";
    }
    if (props.secondaryDark) {
      return "btn_secondary_dark";
    }
    if (props.outline) {
      return "btn_outline";
    }
    if (props.transparent) {
      return "btn_get";
    }
    if (props.transparentHome) {
      return "btn_get btn_get_home";
    }
    if (props.light) {
      return "btn_light";
    }
    if (props.cookie) {
      return "software_banner_btn cookie";
    } else if (props.aboutVideo) {
      return "video_btn";
    } else {
      return "software_banner_btn";
    }
  };

  return generateButtonComponent(props);
};

export default Button;

import React, { FC } from "react";
import { StaticQuery, graphql } from "gatsby";
import UT from "underscore.template";
import htmlParser from "react-markdown/plugins/html-parser";
import MarkDown from "react-markdown/with-html";
import breaks from "remark-breaks";
import { TranslateProps } from "../../types";

export const trObject = (
  translations: Array<{ key: string; value: string }>,
  name: string
) => {
  const foundTranslation = translations.find((item) => item.key === name);
  return foundTranslation ? foundTranslation.value : name;
};

const parseHtml = htmlParser({
  isValidNode: (node: any) => node.type !== "script",
  processingInstructions: [
    /* ... */
  ],
});

export const templateRender = (text: string, context: any): string => {
  const rpText = text.replace("$%", "<%").replace("%$", "%>");
  if (context) {
    try {
      return UT(rpText)(context);
    } catch (e) {}
  }

  return text;
};

const query = graphql`
  query {
    allHexomaticKeywords {
      totalCount
      nodes {
        key
        value
        createdAt
        updatedAt
      }
    }
  }
`;

const Translate: FC<TranslateProps> = ({ name, children, context }) => (
  <StaticQuery
    query={query}
    render={(data) => {
      const isShortText = name.indexOf("_CONTENT") === -1;
      let translatedText = name;
      if (
        data.allHexomaticKeywords &&
        data.allHexomaticKeywords.nodes &&
        data.allHexomaticKeywords.nodes.length > 0
      ) {
        translatedText = templateRender(
          trObject(data.allHexomaticKeywords.nodes, name),
          context
        );
      }

      if (children && typeof children === "function") {
        return children(translatedText);
      }
      return (
        <MarkDown
          source={translatedText}
          astPlugins={[parseHtml]}
          escapeHtml={false}
          disallowedTypes={isShortText ? ["paragraph"] : []}
          unwrapDisallowed={isShortText}
          plugins={[breaks]}
        />
      );
    }}
  />
);

export { Translate };
